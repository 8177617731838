<script setup>
import Button from '@/Components/Button/Button.vue';
import Card from '@/Components/DataCard.vue';
import StaffLayout from '@/Layouts/StaffLayout.vue';
import { objectMap } from '@/utilities';
import { useForm } from '@inertiajs/vue3';
import { inject } from 'vue';
const route = inject('route');
const props = defineProps({
    user: {
        type: Object,
        required: true,
    },
});

const form = useForm({
    ...props.user,
});

const sendForm = () => {
    form.put(
        route('staff.profile.update', {
            profile: props.user.id,
        }),
        {
            preserveState: true,
            preserveScroll: true,
            onError: (errors) => (form.errors = objectMap(errors, (error) => [error])),
        }
    );
};
</script>

<template>
    <StaffLayout title="Flexable" :h1="$t('Profile')" narrow>
        <Card :title="$t('Profile data')">
            <FormKit type="form" id="registration-example" @submit="sendForm" :actions="false">
                <div class="grid gap-5">
                    <FormKit
                        type="text"
                        :placeholder="$t('First name')"
                        name="first_name"
                        :label="$t('First Name')"
                        v-model="form.first_name"
                        :errors="form.errors.first_name"
                        :value="form.first_name"
                    />
                    <FormKit
                        type="text"
                        :placeholder="$t('Last name')"
                        name="last_name"
                        :label="$t('Last Name')"
                        v-model="form.last_name"
                        :errors="form.errors.last_name"
                        :value="form.last_name"
                    />
                    <FormKit
                        type="email"
                        :placeholder="$t('E-mail')"
                        name="email"
                        :label="$t('E-mail')"
                        v-model="form.email"
                        :errors="form.errors.email"
                        :value="form.email"
                    />

                    <Button
                        :processing="form.processing"
                        :recentlySuccessful="form.recentlySuccessful"
                        text="Wijzigingen opslaan"
                        icon="/icon/done-white.svg"
                    />
                </div>
            </FormKit>
        </Card>
        <!-- <PasswordUpdateForm /> -->
    </StaffLayout>
</template>
;
